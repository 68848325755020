// import logo from './Logos/Builtinsoftlogo.png.png'
// import logo1 from './Logos/Builtinsoft logo Official 4x-04.png'
// import quotes from './quotes.png';
// import telephone from './telephone.svg';
import dami from './demoImg.jpg';
import PK from './pak.png'
// import whatsup from './Vector.png';

const img ={
  // logo:logo,
  // logo1:logo1,
  // telephone:telephone,
  dami:dami,
  pk:PK
  // quotes:quotes,
  // whatsup:whatsup,
}
export {img }