import logo from './logo.svg';
import './App.scss';
import Routing from './routes/routing';

function App() {
  return (
    <div className="App">
      <Routing/>
    </div>
  );
}

export default App;
